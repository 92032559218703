<template>
  <QLayout
    view="hhr LpR fFr"
    class="bg-gradient"
  >
    <QPageContainer>
      <QPage class="flex flex-center text-center bg-gradient">
        <section class="text-center full-width">
          <div
            class="q-pb-xl"
            style="margin-top:350px; padding-top:300px"
          >
            <QImg
              :src="cdnImg($t('images.404'))"
              loading="lazy"
              class="q-mx-auto absolute-top"
              style="max-width:720px; max-height:670px;"
            />
            <AppContent
              tag="h4"
              class="text-h4 q-my-none text-weight-black"
              path="error.404.title"
            />
            <AppContent
              tag="h5"
              class="text-h5 q-my-lg"
              path="error.404.subtitle"
            />
            <HcButton
              variant="positive"
              :to="{name: 'home'}"
              label="error.404.button"
            />
          </div>
        </section>
      </QPage>
    </QPageContainer>
  </QLayout>
</template>
